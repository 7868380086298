import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";

export default function CorporateUsers() {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };

  ////////////////////////////////
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(true);
  const [corporates, setCorporates] = useState([]);

  const navigate = useNavigate();

  const columns = [
    {
      id: "id",
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      id: "name",
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      id: "email",
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      id: "company_name",
      name: "Company Name",
      selector: (row) => row.company_name,
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <Link className='btn btn-info btn-sm btn-space-custom' to={`/corporate-user/edit?id=${row.id}`}>
            Edit
          </Link>{" "}
          {row.id !== localStorage.getItem("id") ? (
            <>
              <button
                className='btn btn-danger btn-sm btn-space-custom'
                onClick={(e) => {
                  handleBlock(e, row.id);
                }}>
                {row.status ? "Block" : "Unblock"}
              </button>{" "}
            </>
          ) : (
            ""
          )}
          <Link className='btn btn-primary btn-sm btn-space-custom' to={`/user-report/${row.id}`}>
            Report
          </Link>{" "}
          
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      // button: true,
    },
  ];

  const handleBlock = async (e, id) => {
     
    const params = {
      user_id: id,
    };
    await axios.post(process.env.REACT_APP_API_URL + `V1/user/handle-block-user`, params, config).then((response) => {
      setMessage(
        <>
          <div>
            <div className='alert alert-danger-custom'>{response.data.message}</div>
          </div>
        </>
      );
      setShowMessage(true);
      if (e.target.innerHTML === "Block") {
        e.target.innerHTML = "Unblock";
      } else {
        e.target.innerHTML = "Block";
      }
      setTimeout(function () {
        setShowMessage(false);
      }, 3000);
    });
  };

  const fetchUsers = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + `V1/user/get-all-corp-users?page=${page}&per_page=10&delay=1`, config);

      setData(response.data.data);
      setTotalRows(response.data.total);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        console.error("API error:", error.message);
      }
    }
  };

  const fetchCorporates = async () => {
    // setLoading(true);

    const response = await axios.get(process.env.REACT_APP_API_URL + `V1/corporates/get-all-corporates`, config);

    setCorporates(response.data.data);
    // setTotalRows(response.data.total);
    // setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const response = await axios.get(
      process.env.REACT_APP_API_URL + `V1/user/get-all-corp-users?page=${page}&per_page=${newPerPage}&delay=1`,
      config
    );

    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const handleSearcFilter = async (search, newPerPage = 10, page = 1) => {
    setLoading(true);

    const response = await axios.get(
      process.env.REACT_APP_API_URL + `V1/user/get-all-corp-users?page=${page}&per_page=10&delay=1&search=${search}`,
      config
    );
    setTotalRows(response.data.total);
    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const handleSearcFilterCorporate = async (search, newPerPage, page) => {
    setLoading(true);

    const response = await axios.get(
      process.env.REACT_APP_API_URL + `V1/user/get-all-corp-users?page=1&per_page=10&delay=1&corporate=${search}`,
      config
    );
    setTotalRows(response.data.total);
    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers(1); // fetch page 1 of users
    fetchCorporates();
  }, []);
  ////////////////////////////////
  return (
    <>
      <div className='content-wrapper'>
        <div className='content-header'>
          <div className='container-fluid'>
            <div className='row mb-2'>
              <div className='col-sm-6'>
                <h1 className='m-0'>Corporation Users</h1>
              </div>
              <div className='col-sm-6'>
                <ol className='breadcrumb float-sm-right'>
                  <li className='breadcrumb-item'>
                    <a href='/#'>Home</a>
                  </li>
                  <li className='breadcrumb-item active'>Corporation User</li>
                </ol>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 mb-2 my-0'>
                <Link to='/corporate-user/create'>
                  <button className='btn btn-primary'>Add New Corporation User</button>
                </Link>
              </div>
            </div>
            <div className='card'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='row'>
                      <div className='col-md-12'>{showMessage ? <p>{message}</p> : <></>}</div>
                      <div className='col-md-2'>
                        <div className='form-group'>
                          <input type='text' placeholder='Search' onChange={(e) => handleSearcFilter(e.target.value)} className='form-control' />
                        </div>
                      </div>
                      <div className='col-md-2'>
                        <div className='form-group'>
                          {2 != localStorage.getItem("role") ? (
                            <>
                              <select className='form-control' onChange={(e) => handleSearcFilterCorporate(e.target.value)}>
                                <option value=''>Corporate</option>
                                {corporates.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className='col-md-3'></div>
                      <div className='col-md-5 mr-0'>
                        <Link to='/corporate-user/import'>
                          <button className='btn btn-primary float-right'>Import Corporation User</button>
                        </Link>
                      </div>
                    </div>
                    <DataTable
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      // defaultSortFieldId="id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
