import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function ChallengesList() {
  const location = useLocation();
  const typeView = location?.state?.type;

  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };

  const navigate = useNavigate();

  ////////////////////////////////
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");

  const [corporateId, setCorporateId] = useState("");
  const [message, setMessage] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedChallengeIds, setSelectedChallengeIds] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("corporateId")) {
      setCorporateId(localStorage.getItem("corporateId"));
    }
  }, []);

  const handleSelectAll = () => {
    if (selectedChallengeIds.length === data.length) {
      setSelectedChallengeIds([]);
    } else {
      setSelectedChallengeIds(data.map((challenge) => challenge.id));
    }
  };

  const columns = [
    {
      id: "select",
      name: (
        <input
          type="checkbox"
          checked={selectedChallengeIds.length === data.length && data.length > 0}
          onChange={handleSelectAll}
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectedChallengeIds.includes(row.id)}
          onChange={() => handleSelect(row.id)}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      width: "50px",
    },
    {
      id: "id",
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      id: "name",
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      id: "challenge_type",
      name: "Challenge Type",
      selector: (row) => row.challenge_type,
      sortable: false,
    },
    {
      id: "created_by",
      name: "Created By",
      selector: (row) => row.user_name,
      sortable: false,
    },
    {
      cell: (row) => (
        <>
          <Link to={`/challenge/view?id=${row.id}`} className='btn btn-primary btn-sm btn-space-custom'>
            Preview
          </Link>
          <Link to={`/challenge/edit?id=${row.id}`} className='btn btn-info btn-sm btn-space-custom'>
            Edit
          </Link>

          <Link
            className='btn btn-danger btn-sm btn-space-custom'
            title='Delete'
            onClick={() => {
              if (window.confirm("Are you sure to delete this Challenge? Action cannot be reversed.")) {
                handleDelete(row.id);
              }
            }}>
            Delete
          </Link>
          {2 == localStorage.getItem("role") ? (
            <>
              <Link to={`/challenge/company-invite?id=${row.id}`} className='btn btn-success btn-sm btn-space-custom'>
                Invite
              </Link>
            </>
          ) : (
            ""
          )}
          {typeView === "sidebar" || typeView === undefined ? (
            ""
          ) : (
            <Link
              to={
                (typeView === "at_a_glance" && `/challenge-report/${row.id}`) ||
                (typeView === "overall" && `/overall-challenge-report/${row.id}`) ||
                (typeView === "achievements" && `/challenge-achievement-report/${row.id}`)
              }
              className='btn btn-primary btn-sm btn-space-custom'>
              Report
            </Link>
          )}
          {row.challenge_type_created === "Corp-admin" && row.paymentmethod === 2 && row.goal > 0 &&(
            row.donation_status === 2 ? (
              <button className="btn btn-secondary btn-sm btn-space-custom" disabled>
                Paid
              </button>
            ) : (
              <Link to={row.session_url} className="btn btn-warning btn-sm btn-space-custom">
              Pay now
              </Link>
            )
          )}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      width: "30%",
      // button: true,
    },
  ];

  const handleDelete = async (id) => {
    const params = {
      id: id,
    };

    await axios.post(process.env.REACT_APP_API_URL + `V1/challenge/delete`, params, config).then((response) => {
      setMessage(
        <>
          <div>
            <div className='alert alert-danger-custom'>{response.data.message}</div>
          </div>
        </>
      );

      const elementToHide = document.getElementById("row-" + id);
      elementToHide.style.display = "none";

      setTimeout(() => {
        setMessage("");
      }, 2000);
    });
  };

  const fetchChallenges = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `V1/challenges/get-all-challenges?page=${page}&per_page=${perPage}&delay=1&search_type=${selectedValue}`,
        config
      );

      setData(response.data.data);
      setTotalRows(response.data.total);
      setLoading(false);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        console.error("API error:", err.message);
      }
    }
  };

  const handlePageChange = (page) => {
    fetchChallenges(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const response = await axios.get(
      process.env.REACT_APP_API_URL + `V1/challenges/get-all-challenges?page=${page}&per_page=${newPerPage}&delay=1&search_type=${selectedValue}`,
      config
    );

    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const handleSearcFilter = async (search, newPerPage = 10, page = 1) => {
    setLoading(true);

    const response = await axios.get(
      process.env.REACT_APP_API_URL + `V1/challenges/get-all-challenges?page=${page}&per_page=10&delay=1&search=${search}`,
      config
    );
    setTotalRows(response.data.total);
    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const handleSearcFilterType = async (search, newPerPage = 10, page = 1) => {
    setLoading(true);

    const response = await axios.get(
      process.env.REACT_APP_API_URL + `V1/challenges/get-all-challenges?page=${page}&per_page=10&delay=1&search_type=${search}`,
      config
    );
    setTotalRows(response.data.total);
    setData(response.data.data);
    setPerPage(newPerPage);
    setSelectedValue(search);
    setLoading(false);
  };

   const handleSelect = (id) => {
    setSelectedChallengeIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((challengeId) => challengeId !== id) 
        : [...prevSelected, id] 
    );
  }

  const handleAllDelete = async () => {
    if (selectedChallengeIds.length === 0) {
      alert("Please select at least one challenge to delete.");
      return;
    }
    const confirmed = window.confirm("Are you sure you want to delete the selected challenges?");
    if (confirmed) {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + `V1/challenge/multipledelete`,
          { challenge_ids: selectedChallengeIds },
          config
        );
        setData(data.filter((item) => !selectedChallengeIds.includes(item.id)));
        setSelectedChallengeIds([]);
        setMessage(<div className="alert alert-danger-custom">{response.data.message}</div>);
    }
  };

  useEffect(() => {
    fetchChallenges(1); // fetch page 1 of users
  }, [corporateId]);
  ////////////////////////////////
  return (
    <>
      <div className='content-wrapper'>
        <div className='content-header'>
          <div className='container-fluid'>
            <div className='row mb-2'>
              <div className='col-sm-6'>
                <h1 className='m-0'>Challenges</h1>
              </div>
              <div className='col-sm-6'>
                <ol className='breadcrumb float-sm-right'>
                  <li className='breadcrumb-item'>
                    <a href='/#'>Home</a>
                  </li>
                  <li className='breadcrumb-item active'>Challenges</li>
                </ol>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 mb-2 my-0'>
                <Link to='/challenge/create'>
                  <button className='btn btn-primary'>Add New Challenge</button>
                </Link>
              </div>
            </div>
            <div className='card'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='row'>
                      <div className='col-md-12'>{message}</div>
                      <div className='col-md-2'>
                        <div className='form-group'>
                          <input type='text' placeholder='Search' onChange={(e) => handleSearcFilter(e.target.value)} className='form-control' />
                        </div>
                      </div>
                      {2 != localStorage.getItem("role") ? (
                        <>
                          <div className='col-md-2'>
                            <div className='form-group'>
                              <select value={selectedValue} className='form-control' onChange={(e) => handleSearcFilterType(e.target.value)}>
                                <option value=''>Select Type</option>
                                <option value='1'>For a cause</option>
                                <option value='2'>Fitness</option>
                                <option value='3'>Habit</option>
                                <option value='4'>Special Stand</option>
                              </select>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <div className='col-md-3 ml-auto'>
                            <div className='form-group'>
                            <button className='btn btn-danger ml-auto d-block' onClick={handleAllDelete} style={{ width: '220px' }}>Delete Selected Challenges</button>
                            </div>
                      </div>
                    </div>
                    <DataTable
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      manualColumnResize={true}
                      // defaultSortFieldId="id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
